import { useState } from 'react'
import Navigator from './Navigator'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom'
import cookies from 'react-cookies'
import Compute from './compute'

export default function App() {
    const [activePage, setActivePage] = useState('compute')
    const navigate = useNavigate()

    const logout = () => {
        cookies.remove('session')
        navigate('/login')
    }

    return (
        <div>
            <Navigator active={activePage} onChange={e => setActivePage(e)} />
            <div className="logout-btn text-slate-50 absolute top-3 right-10 cursor-pointer" onClick={logout}>
                <ExitToAppIcon />
            </div>
            {activePage === 'compute' && <Compute />}
        </div>  
    )
}