import axios from 'axios'
import conf from '../conf'
import cookies from 'react-cookies'

/**
 * Makes a request to the backend API.
 * @param {string} path - The path of the request, relative to the API base.
 * @param {string} [method] - The method to use. Defaults to 'GET'.
 * @param {any} [body] - The body to send with the request.
 * @returns {Promise<any>} - A promise that resolves with the response body, or rejects with the error message.
 */
export default function request(path, method = 'GET', body = undefined) {
    return new Promise((resolve, reject) => {
        let headers = {}
        headers[conf.api.sessionHeader] = cookies.load('session') || ''
        const url = `${conf.api.base}${path}`
        axios({
            method,
            url,
            data: body,
            headers
        }).then(({data}) => {
            if(data.error) {
                reject(data.msg)
            } else {
                resolve(data.data)
            }
        })
        .catch(err => reject(err.message || err))
    })
}