import { Button, Input, message } from 'antd'
import { useEffect, useState } from 'react'
import request from '../../utils/request'
import cookies from 'react-cookies'

export default function Login() {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // Check if user is valid of session
        request('/session/isValid')
            .then((data) => {
                if(data.valid) {
                    window.location.href = '/app'
                }
            })
            .catch(err => message.error(err.message || err))
    }, [])

    const login = () => {
        setLoading(true)
        request('/session/login', 'POST', { username, password })
            .then((data) => {
                if(data.hash) {
                    cookies.save('session', data.hash)
                    window.location.href = `/app`
                }
            })
            .catch(err => {
                message.error(err)
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className="login-page">
            <div className="auth-card bg-[#ffffff] mt-12 max-w-[400px] rounded-2xl mx-auto px-7 py-4">
                <div className="text-gray text-center">Welcome to Centre of <a href="https://streampeg.com" target="_blank" rel="noopener" className="font-bold text-blue-600 font-medium hover:underline">StreamPEG</a></div>
                <div className="text-sm text-gray-500 text-center">Please enter your credentials for continue</div>
                <Input
                    placeholder='Username'
                    className='mt-3'
                    autoFocus
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    disabled={loading}
                    onKeyUp={e => e.keyCode === 13 && login()}
                />
                <Input
                    placeholder="Password"
                    className='mt-3'
                    value={password}
                    disabled={loading}
                    onChange={e => setPassword(e.target.value)}
                    type='password'
                    onKeyUp={e => e.keyCode === 13 && login()}
                />
                <div className="flex items-center mt-3 w-fit ml-auto mr-0">
                    <Button
                        type='primary'
                        onClick={login}
                    >Login</Button>
                </div>
            </div>
        </div>
    )
}