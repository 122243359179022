import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Input, Radio, RadioGroup, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import request from "../../../utils/request";
import { message } from "antd";

export default function ChangeDurationDialog({ open, onClose, compute, onChanged }) {
    const [type, setType] = useState('increase')
    const [days, setDays] = useState(1)
    const [daysValid, setDaysValid] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const v = parseInt(days)
        setDaysValid(!isNaN(v))
    }, [days])

    const submit = () => {
        setLoading(true)
        request('/compute/changeDuration', 'POST', { id: compute.id, type, days: parseInt(days) })
            .then(({ updated }) => {
                onClose()
                onChanged(updated)
            })
            .catch(err => {
                message.error(err.message || err)
            })
            .finally(() => setLoading(false))
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <div>Change Duration</div>
            </DialogTitle>
            {compute && (
                <DialogContent className="flex flex-col gap-4">
                    <div className="text-sm italic">IP: <u>{compute.ip}</u></div>
                    <FormControl disabled={loading}>
                        <FormLabel>Type</FormLabel>
                        <RadioGroup row value={type} onChange={e => setType(e.target.value)}>
                            <FormControlLabel value='increase' control={<Radio />} label="Increase" />
                            <FormControlLabel value='decrease' control={<Radio />} label="Decrease" />
                        </RadioGroup>
                    </FormControl>
                    <TextField label='Duration (days)' size='small' type='number' autoComplete="off"
                        value={days}
                        onChange={e => setDays(e.target.value)}
                        error={!daysValid}
                        helperText={daysValid ? '' : 'Invalid number'}
                        disabled={loading}
                    />
                </DialogContent>
            )}
            <DialogActions>
                <div className="w-fit mx-auto mr-0 gap-2 flex">
                    <button className="bg-slate-500 rounded-lg px-3 py-1 text-white shadow-2xl hover:bg-slate-600" onClick={onClose}>Cancel</button>
                    <button className="bg-blue-500 rounded-lg px-3 py-1 text-white shadow-2xl hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed" disabled={loading} onClick={submit}>Submit</button>
                </div>
            </DialogActions>
        </Dialog>
    )
}