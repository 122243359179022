import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';

const pages = [
    { name: "Compute", icon: <DeveloperBoardIcon />, key: "compute" },
]

export default function Navigator({ active, onChange }) {   

    return (
        <div className="navigator w-fit flex items-center gap-2 mx-auto mt-3">
            {pages.map((page, i) => (
                <div className={`page flex items-center gap-1 px-4 py-1 rounded-lg cursor-pointer ${active === page.key ? 'bg-slate-100' : 'text-slate-100'} transition-all duration-100`} key={i} onClick={() => onChange(page.key)}>
                    <div className="icon">{page.icon}</div>
                    <div className="name">{page.name}</div>
                </div>
            ))}
        </div>
    )
}