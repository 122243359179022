import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './pages/login'
import AppPage from './pages/app'
import NotFound from './pages/not_found'

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/app' element={<AppPage />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}