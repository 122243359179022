import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import validator from 'validator'
import { Input } from '@mui/material'
import { useEffect, useState } from "react";
import request from "../../../utils/request";
import { message } from 'antd'

export default function AddDialog({ open, onClose, onAdded }) {

    const [ipAddress, setIpAddress] = useState('')
    const [label, setLabel] = useState('')
    const [validIpAddress, setValidIpAddress] = useState(validator.isIP(ipAddress, 4))
    const [validLabel, setValidLabel] = useState(label.trim().length > 3)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setValidLabel(label.trim().length > 3)
    }, [label])

    useEffect(() => {
        setValidIpAddress(validator.isIP(ipAddress, 4))
    }, [ipAddress])


    const submit = () => {
        setLoading(true)
        request('/compute/add', 'POST', { ip: ipAddress, name: label.trim() })
            .then(() => {
                onClose()
                setIpAddress('')
                setLabel('')
                onAdded()
            })      
            .catch(err => {
                message.error(err.message || err)
            })
            .finally(() => setLoading(false))
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <div>Add Compute</div>
            </DialogTitle>
            <DialogContent>
                <div className="flex flex-col">
                <Input
                    placeholder="IP Address"
                    value={ipAddress}
                    onChange={e => setIpAddress(e.target.value)}
                    error={!validIpAddress}
                    required
                    disabled={loading}
                />
                {!validIpAddress && <div className="text-red-500 text-xs">Invalid IP Address</div>}

                <Input 
                    placeholder="Label"
                    className="mt-3"
                    value={label}
                    onChange={e => setLabel(e.target.value)}
                    error={!validLabel}
                    disabled={loading}
                />
                {!validLabel && <div className="text-red-500 text-xs">Invalid Label</div>}
                </div>
            </DialogContent>
            <DialogActions>
                <button className="bg-red-500 text-white px-3 py-1 rounded-lg shadow-lg" onClick={onClose}>Cancel</button>
                <button className="bg-blue-500 text-white px-3 py-1 rounded-lg shadow-lg disabled:bg-gray-400 disabled:cursor-not-allowed" disabled={loading || !validIpAddress || !validLabel} onClick={submit}>Submit</button>
            </DialogActions>
        </Dialog>
    )
}